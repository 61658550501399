import React from 'react';

const AboutSouthEastWater = () => (
  <section className="aq-feature-bg pt-8 pb-8 bg-green text-white">
    <div className="container-fluid">
      <div className="component-wrap">
        <div className="row">
          <div className="col-lg-6 pb-4 pb-lg-0 d-flex align-items-center">
            <div>
              <h1 className="h1-two-line-v2 white mb-15 mt-6 text-center">
                <span>South East Water</span>
              </h1>
              <h3 className="text-white pt-4">
                We supply drinking water to 2.2 million people in the south east
                of England. Including you! We know that we rely on nature for
                our water, it comes from the rivers and streams all around us.
                That’s why we are guardians of the environment. Just like the
                Aquanauts!
              </h3>
              <br />
              <a
                className="btn btn-light-blue"
                href="https://www.southeastwater.co.uk/about"
                target="_blank"
                rel="noreferrer"
              >
                Read more
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src="/images/Characters/Feature-BG-2.svg"
              className="w-100 p-8"
              alt="Aquanaut Isla"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutSouthEastWater;
