import React from 'react';

import AboutSouthEastWater from '../components/AboutSouthEastWater';
import AboutTheAquanauts from '../components/AboutTheAquanauts';
import Layout from '../components/layout';
import Seo from '../components/Seo';

const About = ({ location }) => (
  <Layout page="about" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'About',
        seoDescription:
          'Learn more about our amazing water saving heroes Isla, Kai and Nero aka the Aquanauts',
      }}
    />
    <AboutTheAquanauts />
    <AboutSouthEastWater />
  </Layout>
);

export default About;
