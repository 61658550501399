import React from 'react';

const AboutTheAquanauts = () => (
  <section className="aq-feature-bg pt-8 pb-8 bg-grey">
    <div className="container-fluid">
      <div className="component-wrap">
        <div className="row">
          <div className="col-lg-6 pb-4 pb-lg-0">
            <h1 className="h1-two-line-v2 pink mb-12 mb-lg-16 mt-6">
              <span>About the</span>
              <br />
              <span>Aquanauts </span>
            </h1>
            <div
              style={{
                position: 'absolute',
                top: '100px',
                left: '450px',
                height: '135px',
                width: '135px',
                transform: 'rotate(180deg)',
              }}
              className="d-none d-lg-block"
            >
              <img
                src="/images/Doodles/Arrows/Arrow-3.svg"
                alt="Illustration of an arrow"
              />
            </div>
            <div className="ml-0 ml-lg-15">
              <div
                style={{
                  position: 'absolute',
                  left: '60px',
                  height: '50px',
                  width: '50px',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Exclamations/Exclamation-1.svg"
                  alt="Illustration of an speech mark"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 pb-4 pb-lg-0 d-flex align-items-center">
            <div className="ml-0 ml-lg-15">
              <p className="mt-5">
                <strong>Hey, it’s Isla! I love all things colourful.</strong>
              </p>
              <p>
                My favourite things are rainbows and I love exploring with Nero
                and Kai to find out all I can about where these come from. Did
                you know that without water, we wouldn’t have rainbows? A
                rainbow is made when sunlight hits rain droplets and the light
                is reflected differently. Everything needs water and that’s why
                I love finding out all the ways we can save water, to make sure
                there is always enough for nature.
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <img
              src="/images/Characters/About-Isla.svg"
              className="my-3 mx-10 mx-lg-0"
              alt="The Aquanauts"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center justify-content-center order-last order-lg-first">
            <img
              src="/images/Characters/About-Nero.svg"
              className="my-3 mx-10 mx-lg-0"
              alt="The Aquanauts"
            />
          </div>
          <div className="col-lg-6 pb-4 pb-lg-0 d-flex align-items-center">
            <div className="">
              <p className="mt-5">
                <strong>Hi, Nero here! I love animals and nature.</strong>
              </p>
              <p className="">
                My favourite animal is an otter because they can hold their
                breath underwater for 8 minutes, and they can dive 60 feet under
                which is the same height as four giraffes. Otters live in and
                around water. There are loads of cool animals that live in
                water, and if they don’t live in water, they use water… like us!
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 pb-4 pb-lg-0 d-flex align-items-center">
            <div className="ml-0 ml-lg-15">
              <p className="mt-5">
                <strong>Hello, I’m Kai! I investigate everything.</strong>
              </p>
              <p>
                I want to be a detective when I’m older. I like finding out how
                things work. I’ve been learning how much water goes into all the
                things we do. I found out that every minute you spend in the
                shower takes a whole bucket of water. I love helping my family
                learn new ways to save water at home.
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <img
              src="/images/Characters/About-Kai.svg"
              className="my-3 pb-5 mx-10 mx-lg-0"
              alt="The Aquanauts"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutTheAquanauts;
